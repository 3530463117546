import React, { useEffect, useState, useContext } from 'react';
import { ref, onValue, update, get } from 'firebase/database';
import { database } from '../firebaseConfig';
import AuthContext from '../AuthContext';
import { DateTime } from 'luxon';
import { FaLock } from 'react-icons/fa'; // Import the FaLock icon
import './GameList.css'; // Import the CSS file

const GameList = () => {
  const { currentUser } = useContext(AuthContext);
  const [games, setGames] = useState([]);
  const [picks, setPicks] = useState({});
  const [weekTriplePlay, setWeekTriplePlay] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [allPicksExist, setAllPicksExist] = useState(false);
  const [users, setUsers] = useState([]); // State to hold the list of users
  const [selectedUser, setSelectedUser] = useState(''); // State to hold the selected user
  const [isAdmin, setIsAdmin] = useState(false);
  const [showUserSelect, setShowUserSelect] = useState(false);

  useEffect(() => {
    const fetchGames = () => {
      const gamesRef = ref(database, 'games');
      onValue(gamesRef, (snapshot) => {
        const data = snapshot.val();
        const gamesList = data ? Object.keys(data).map((id) => ({ id, ...data[id] })).filter(game => game.active) : [];
        setGames(gamesList);

        if (currentUser) {
          const picksRef = ref(database, `picks/${currentUser.uid}`);
          onValue(picksRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
              setPicks(data);
              setAllPicksExist(gamesList.every(game => data[game.id]));

              // Set weekTriplePlay based on saved picks
              const triplePlay = {};
              gamesList.forEach(game => {
                if (data[game.id]?.triplePlay) {
                  triplePlay[game.weekNumber] = game.id;
                }
              });
              setWeekTriplePlay(triplePlay);
            }
          });
        }
      });
    };

    const fetchUsers = () => {
      const usersRef = ref(database, 'users');
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const usersList = Object.keys(data).map((id) => ({ id, ...data[id] }));
          usersList.sort((a, b) => a.name.localeCompare(b.name));
          setUsers(usersList);
        }
      });
    };

    fetchGames();

    if (currentUser) {
      const userRef = ref(database, `users/${currentUser.uid}`);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        if (data?.admin) {
          setIsAdmin(true);
          fetchUsers();
        }
      });
    }
  }, [currentUser]);

  const handlePickChange = (gameId, pick) => {
    const weekNumber = games.find(game => game.id === gameId).weekNumber;
    setPicks({
      ...picks,
      [gameId]: { pick, triplePlay: weekTriplePlay[weekNumber] === gameId },
    });
  };

  const handleTriplePlayChange = (gameId, weekNumber) => {
    setWeekTriplePlay((prevTriplePlay) => {
      const newTriplePlay = { ...prevTriplePlay };

      // Remove triple play from any other game in the same week
      Object.keys(newTriplePlay).forEach((week) => {
        if (week === weekNumber.toString() && newTriplePlay[week] !== gameId) {
          delete newTriplePlay[week];
        }
      });

      // Toggle triple play for the selected game
      if (newTriplePlay[weekNumber] === gameId) {
        delete newTriplePlay[weekNumber];
      } else {
        newTriplePlay[weekNumber] = gameId;
      }

      // Update picks to reflect the triple play change immediately
      setPicks((prevPicks) => ({
        ...prevPicks,
        [gameId]: {
          ...prevPicks[gameId],
          triplePlay: newTriplePlay[weekNumber] === gameId, // Set triplePlay based on current selection
        },
      }));

      return newTriplePlay;
    });
  };

  const calculateScores = (picks, games) => {
    let scores = {};
    games.forEach((game) => {
      const gameId = game.id;
      const pick = picks[gameId];
      if (pick && game.winner) { // Only calculate score if the game has a winner
        const isCorrectPick = game.winner === 'push' || (pick.pick === 'home' && game.winner === 'home') || (pick.pick === 'away' && game.winner === 'away');
        const score = isCorrectPick ? 1 : 0;
        if (pick.triplePlay) {
          scores[gameId] = score * 3; // Triple play scores triple points
        } else {
          scores[gameId] = score;
        }
      }
    });
    return scores;
  };

  const submitPicks = async () => {
    // Check if a triple play is selected for each week
    const weeks = new Set(games.map(game => game.weekNumber));
    const triplePlayWeeks = new Set(Object.keys(weekTriplePlay).map(Number));

    if (weeks.size !== triplePlayWeeks.size) {
      setSubmissionStatus({ type: 'error', message: `Error submitting picks: Please select a Triple Play for each week before submitting.` });
      return;
    }

    if (!currentUser) {
      setSubmissionStatus({ type: 'error', message: 'You need to log in to submit picks.' });
      return;
    }

    const userId = selectedUser || currentUser.uid; // Use selected user if available, otherwise use current user
    const userEmail = selectedUser ? users.find(user => user.id === selectedUser).email : currentUser.email;

    // Requery the database for the latest game data
    const gamesRef = ref(database, 'games');
    const gamesSnapshot = await get(gamesRef);
    const latestGames = gamesSnapshot.val() ? Object.keys(gamesSnapshot.val()).map((id) => ({ id, ...gamesSnapshot.val()[id] })) : [];

    const allGamesLocked = latestGames.every(game => game.locked);
    if (allGamesLocked) {
      setSubmissionStatus({ type: 'error', message: 'All picks are locked.' });
      setTimeout(() => {
        setSubmissionStatus(null);
      }, 3000);
      return;
    }

    const updateData = {};

    // Update picks and include all games (active and inactive)
    latestGames.forEach((game) => {
      if (picks[game.id] && game.active) {
        // Ensure triplePlay is correctly set based on weekTriplePlay
        const weekNumber = game.weekNumber;
        const isTriplePlay = weekTriplePlay[weekNumber] === game.id;
        updateData[`picks/${userId}/${game.id}`] = { ...picks[game.id], triplePlay: isTriplePlay };
        updateData[`picks/${userId}/email`] = userEmail;
      }
    });

    // Fetch existing scores
    const scoresRef = ref(database, `scores/${userId}`);
    const scoresSnapshot = await get(scoresRef);
    const existingScores = scoresSnapshot.val() || {};

    // Calculate new scores
    const newScores = calculateScores(picks, latestGames);

    // Merge new scores with existing scores
    const mergedScores = { ...existingScores, ...newScores };
    updateData[`scores/${userId}`] = mergedScores;

    try {
      await update(ref(database), updateData);
      setSubmissionStatus({
        type: 'success',
        message: allPicksExist ? 'Picks updated successfully!' : 'Picks submitted successfully!',
      });

      setTimeout(() => {
        setSubmissionStatus(null);
      }, 3000);
    } catch (error) {
      setSubmissionStatus({ type: 'error', message: `Error submitting picks: ${error.message}` });
    }
  };

  return (
    <div className="relative max-w-md mx-auto pt-4 pb-10">
      {submissionStatus && (
        <div className={`fixed top-0 left-0 right-0 p-4 z-50 ${submissionStatus.type === 'success' ? 'bg-green-500' : 'bg-red-500'} text-white text-center`}>
          {submissionStatus.message}
        </div>
      )}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-accent pl-4">Game List</h2>
        {currentUser && isAdmin && (
          <button
            onClick={(e) => {
              e.preventDefault();
              if (showUserSelect) {
                setSelectedUser('');
              }
              setShowUserSelect(!showUserSelect);
            }}
            className="mr-2 text-yellow-500 px-4 py-2 rounded-md cursor-pointer outline outline-1 outline-yellow-500"
          >
            {showUserSelect ? 'pick for myself' : 'pick for someone else'}
          </button>
        )}
      </div>
      {currentUser && isAdmin && showUserSelect && (
        <div className="mb-4">
          <label htmlFor="userSelect" className="block text-accent">Select User:</label>
          <select
            id="userSelect"
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            className="mt-1 bg-gray-800 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value="">-- Select a user --</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.name} ({user.email})
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="grid grid-cols-1 gap-4">
        {games.map((game) => (
          <div key={game.id} className="bg-gray-800 p-4 rounded shadow-md">
            <div className="mb-2 flex items-center">
              <span className="font-semibold">{game.awayTeam}</span>&nbsp;@&nbsp;<span className="font-semibold">{game.homeTeam}</span>
              {game.locked && <FaLock className="ml-2 text-red-500 inline-block" />}
            </div>
            <div className="mb-2 text-gray-400">
              {DateTime.fromISO(game.dateTime).toFormat('EEEE, MMMM dd, yyyy h:mm a')} <br /> {game.location}
            </div>
            <div className="mb-2 flex justify-between items-center">
              <div className="flex flex-col">
                <label className={`py-1 flex items-center ${picks[game.id]?.pick === 'away' ? 'selected-option' : ''}`}>
                  <input
                    type="radio"
                    name={game.id}
                    value="away"
                    onChange={() => handlePickChange(game.id, 'away')}
                    checked={picks[game.id]?.pick === 'away'}
                    disabled={game.locked}
                    className={`mr-2 ${game.locked ? 'radio-disabled' : ''}`}
                    style={{ transform: 'scale(1.5)' }} // Scale up the radio button
                  />
                  {game.awayTeam} ({game.awaySpread > 0 ? `+${game.awaySpread}` : game.awaySpread})
                </label>
                <label className={`py-1 flex items-center ${picks[game.id]?.pick === 'home' ? 'selected-option' : ''}`}>
                  <input
                    type="radio"
                    name={game.id}
                    value="home"
                    onChange={() => handlePickChange(game.id, 'home')}
                    checked={picks[game.id]?.pick === 'home'}
                    disabled={game.locked}
                    className={`mr-2 ${game.locked && picks[game.id]?.pick === 'home' ? 'checked-disabled' : ''}`}
                    style={{ transform: 'scale(1.5)' }} // Scale up the radio button
                  />
                  {game.homeTeam} ({game.homeSpread > 0 ? `+${game.homeSpread}` : game.homeSpread})
                </label>
              </div>
              <label className={`block py-1 flex items-center ${weekTriplePlay[game.weekNumber] === game.id ? 'selected-option' : ''}`}>
                <input
                  type="checkbox"
                  onChange={() => handleTriplePlayChange(game.id, game.weekNumber)}
                  checked={weekTriplePlay[game.weekNumber] === game.id}
                  disabled={game.locked}
                  className={`mr-2 ${game.locked && weekTriplePlay[game.weekNumber] === game.id ? 'checked-disabled' : ''}`}
                  style={{ transform: 'scale(1.5)' }} // Scale up the checkbox
                />
                Triple Play
              </label>
            </div>
          </div>
        ))}
      </div>
      <button
        onClick={submitPicks}
        className="mt-4 bg-accent text-gray-800 px-4 py-2 rounded hover:bg-accent-dark text-gray-800"
      >
        {allPicksExist ? 'Update Picks' : 'Submit Picks'}
      </button>
    </div>
  );
};

export default GameList;
