// src/components/UserProfile.js
import React, { useContext, useState, useEffect } from 'react';
import { FaTools } from 'react-icons/fa'; // Added FaComments
import { Link } from 'react-router-dom';
import AuthContext from '../AuthContext';
import LogoutButton from './LogoutButton';
import { ref, onValue } from 'firebase/database';
import { database } from '../firebaseConfig';

const UserProfile = ({ name }) => {
  const { currentUser } = useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (currentUser) {
      const userRef = ref(database, `users/${currentUser.uid}`);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        setIsAdmin(data?.admin || false);
      });
    }
  }, [currentUser]);

  if (!currentUser) {
    return <div className="text-center text-red-500">No user is logged in</div>;
  }

  return (
    <div className="max-w-md mx-auto pb-20 pt-4">
      <h2 className="text-2xl font-bold mb-4 text-accent pl-4">Profile</h2>
      <div className="bg-gray-800 p-4 rounded shadow-md">
        <p className="mb-1">Name: {name}</p>
        <p className="mb-4">Email: {currentUser.email}</p>
        <div className="flex justify-between items-center mt-4">
          <LogoutButton />
          <Link to="/reset-password" className="text-accent hover:underline">Reset Password</Link>
        </div>
      </div>
      <div className="flex justify-center items-center mt-4">
        {isAdmin && (
          <Link to="/admin" className="flex items-center bg-yellow-500 text-gray-800 text-lg font-bold py-2 px-4 rounded-lg hover:bg-yellow-400">
            <FaTools size={20} className="mr-2 text-gray-800" />
            Admin Panel
          </Link>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
