import React from 'react';
import { FaLock, FaLockOpen, FaCheckCircle, FaTimesCircle, FaTrash } from 'react-icons/fa';
import { DateTime } from 'luxon';

const GameItem = ({ game, editGame, deleteGame }) => {
  return (
    <li 
      className="flex justify-between items-center bg-gray-700 p-2 rounded mb-2 hover:bg-gray-600 cursor-pointer"
      onClick={() => editGame(game)}
    >
      <div className="flex items-center mb-2 mt-2 justify-center">
        <div className="flex flex-col pr-4 items-center">
          {game.locked ? <FaLock className="text-red-500 mb-2" /> : <FaLockOpen className="text-yellow-500 mb-2" />}
          {game.active ? <FaCheckCircle className="text-green-500" /> : <FaTimesCircle className="text-red-500" />}
        </div>
        <div className="text-left">
          <span>{game.awayTeam} ({game.awaySpread > 0 ? `+${game.awaySpread}` : game.awaySpread}) @</span><br />
          <span>{game.homeTeam} ({game.homeSpread > 0 ? `+${game.homeSpread}` : game.homeSpread})</span>
        </div>
      </div>
      <div className="text-gray-400 mb-2 text-center">
        <span>{DateTime.fromISO(game.dateTime).toFormat('MM/dd/yy hh:mm a')}</span><br />
        <span>{game.location}</span>
      </div>
      <div className="flex space-x-2 justify-center">
        <button
          onClick={(e) => {
            e.stopPropagation();
            deleteGame(game.id);
          }}
          className="text-accent px-2 py-1 rounded flex items-center justify-center"
        >
          <FaTrash />
        </button>
      </div>
    </li>
  );
};

export default GameItem;