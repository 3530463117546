import React from 'react';

const Modal = ({ children, onClose }) => {
  return (
    <div className="fixed inset-2 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full relative border border-accent">
        <button className="absolute top-2 right-2 text-xl text-gray-400 hover:text-gray-200" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;