import React, { useRef, useEffect, useState } from 'react';
import LeaderboardRow from './LeaderboardRow';
const LeaderboardTable = ({ weeks, sortedScores, users, highestScoresByWeek, showWeeklyWinners, handleUserClick, expandedUserId, gamesByWeek, userPicks, isAdmin, currentUser }) => {
  const [nameColumnWidth, setNameColumnWidth] = useState(0);
  const nameColumnRef = useRef(null);

  useEffect(() => {
    if (nameColumnRef.current) {
    setNameColumnWidth(nameColumnRef.current.offsetWidth);
  }
  }, [users]);
  
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-gray-800 text-white rounded shadow-md">
        <thead className="sticky top-0 border-b-2 border-gray-700 border-solid font-bold">
          <tr>
            <th ref={nameColumnRef} className="px-2 pl-4 py-2 sticky text-left left-0 bg-gray-800">Name</th>
            <th className="px-2 py-2 sticky text-center bg-gray-800 text-accent" style={{ left: nameColumnWidth || 150 }}>Total</th>
            {weeks.map(week => (
              <th key={week} className="px-4 py-2 text-center">{week}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedScores.map((score, index) => (
            <LeaderboardRow
              key={score.userId}
              score={score}
              index={index}
              weeks={weeks}
              users={users}
              highestScoresByWeek={highestScoresByWeek}
              showWeeklyWinners={showWeeklyWinners}
              handleUserClick={handleUserClick}
              expandedUserId={expandedUserId}
              gamesByWeek={gamesByWeek}
              userPicks={userPicks}
              isAdmin={isAdmin}
              currentUser={currentUser}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LeaderboardTable;